<template>
  <div />
</template>
<script>
const APPROVE_STATUS_GUID = '3a928e12-eca1-4be3-bff7-df05b5155bde';

export default {
  name: 'ReturnProject',
  props: {
    collection: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    selected: null,
  }),
  created(){
    this.selected = this.collection.master.selected;

    if (!this.selected){
      jet.msg({
        text:'Необходимо выбрать запись!',
        color:'warning'
      });
      return;
    }

    if (this.selected.vcroutesVerstatus !== APPROVE_STATUS_GUID){
      jet.msg({
        text:'Действие доступно для только маршрутов со статусом "Утвержден"!',
        color:'warning'
      });
      return;
    }

    this.returnProject(this.selected.vcroutesId);
  },
  methods:{
    async returnProject(routeId){
      let {id: userId, tenant: tenantId} = this.$store.state.profile;
      const opts = {
        type: 'query',
        query: 'feb1b1ec-48b9-46d4-b59e-93d9ff3f1f09.returnProject',
        params: {
          routeId: routeId,
          tenantid: tenantId,   //this.selected._sec_attrsTenantid,
          userid: userId        //this.selected._sec_attrsUserid,
        }
      }

      try{
        const resp = await jet.http.post(opts);
        if (resp.error){
          throw resp.error;
        }
        jet.msg({
          text:`Изменение статуса для маршрута № ${this.selected.vcroutesRoutecode}
            ${this.selected.vcroutesRoutename} выполнено успешно`
        });
      }catch(e){
        jet.msg({
          text:'В процессе изменения статуса произошла ошибка',
          color:'warning'
        });
        
        console.error('ReturnProject:', e);
      }
      this.collection.refresh(routeId);
    }
  }
}
</script>
